import React from 'react'
import style from 'styled-components'
import {
  FaStripe,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcApplePay
} from 'react-icons/fa'

const Button = style.button`
  grid-area: stripe;
  font-size: 100%;
  color: dimgray;
  background: gold;
  padding: .25em;
  padding-left: .5em;
  padding-right: .5em;
`
const Container = style.div`
  display: grid;
  grid-template-rows: 1fr 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "text text"
    "powered cards";
  grid-gap: .25em;
  width: 100%;
  height: 100%;
`
const Text = style.div`
  grid-area: text;
  place-self: center center;
  font-weight: 800;
`
const StripeFooter = style.div`
  font-size: 50%;
  grid-area: powered;
  place-self: center start;
`
const StripeIcon = style(FaStripe)`
  font-size: 350%;
  vertical-align: middle;
`
const PaymentsIcons = style.div`
  font-size: 130%;
  grid-area: cards;
  place-self: center end;
`

const ENV = 'prod'
const StripeKeys = {
  test: 'pk_test_HDKquod7N16aDJhtbxxKDm1e00wQ710sIt',
  prod: 'pk_live_2npsnl9oe2T2vE7Ig2p6mQ0000yd9c7Vz0'
}
const Donation1SKUs = {
  test: 'sku_FCofuF7xIJdKob',
  prod: 'sku_FMCyui54pevzKk'
}

const StripeKey = StripeKeys[ENV]
const Donation1SKU = Donation1SKUs[ENV]

const StripeButton = ({ children, amount, className }) => {
  var donate1 = N => console.log(N)
  if (typeof window !== 'undefined') {
    const stripe = window.Stripe(StripeKey)

    donate1 = N => {
      stripe
        .redirectToCheckout({
          items: [{ sku: Donation1SKU, quantity: parseInt(N, 10) }],
          // Do not rely on the redirect to the successUrl for fulfilling purchases, customers may not always reach the success_url after a successful payment. Instead use one of the strategies described in https://stripe.com/docs/payments/checkout/fulfillment
          successUrl: 'https://amicidiandrea.org/thanks',
          cancelUrl: 'https://amicidiandrea.org/donations'
        })
        .then(function (result) {
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to your customer.
            // TODO: Display error to the user
            console.log(result.error.message)
          }
        })
    }
  }

  return (
    <Button type="submit" className={className} onClick={() => donate1(amount)}>
      <Container>
        <Text>{children}</Text>
        <StripeFooter>
          Powered by <StripeIcon />
        </StripeFooter>
        <PaymentsIcons>
          <FaCcVisa /> <FaCcMastercard /> <FaCcAmex /> <FaCcApplePay />
        </PaymentsIcons>
      </Container>
    </Button>
  )
}

export default StripeButton
