import React from 'react'
import style from 'styled-components'
// import { FaUniversity, FaCopy } from 'react-icons/fa'

const Container = style.div`
  display: flex;
  width: 100%;
  place-content: center;
`
const Code = style.code`
  font-size: 120%;
  background: cornflowerblue;
  color: white;
  padding: .6em 1em;
  border-radius: 5px;
  box-shadow: 0 4px 8px lightgray;
  @media (max-width: 560px) {
    font-size: 80%;
  }
`
// TODO: Add Bank icon and Copy To Clipboard button
// const BankIcon = style(FaUniversity)`
//   vertical-align: middle;
//   padding-right: 1em;
// `
// const CopyIcon = style(FaCopy)`
//   width: .8em;
//   height: .8em;
//   padding: .2em 0px;
//   margin: 0 .2em;
//   border-radius: 5px;
//   background: lightgray;
// `

const IBAN = ({ children }) => (
  <Container>
    <Code>
      {/* <BankIcon/> */}
      {children}
    </Code>
    {/* <CopyIcon/> */}
  </Container>
)

export default IBAN
