import React from 'react'
// import style from 'styled-components'
import { navigate } from 'gatsby'
import PaypalExpressBtn from 'react-paypal-express-checkout'
// import { FaCcPaypal } from 'react-icons/fa'

// const Button = style.button`
//   display: flex;
//   font-size: 100%;
// `

// const PayPalIcon = style(FaCcPaypal)`
//   place-self: center center;
//   margin: auto;
//   width: 100%;
//   height: 100%;
// `

const client = {
  sandbox:
    'ARmtZjxnSLLNsMFaB-QQxuu-5bv18mTXKLlNyZ0CsZ3jJTI2gr553tHHQ5qFQYOJD3ljClnBVNDN5Hze',
  production:
    'AVaZ96Ei5JLVfR0ECQf2bp7-J-IYWQp6Dn84rYfYqqnVmd7lu3SsnkP8hY4lGrTc6-ubqxMdqRGoIJId'
}

const PayPalButton = ({ amount, className }) => {
  if (typeof window !== 'undefined') {
    return (
      <div className={className}>
        <PaypalExpressBtn
          client={client}
          env="production"
          currency={'EUR'}
          total={amount}
          onError={() => {
            console.log('PayPal Error')
            navigate('/donations/')
          }}
          onCancel={() => {
            console.log('PayPal Cancelled')
            navigate('/donations/')
          }}
          onSuccess={() => {
            console.log('PayPal Success')
            navigate('/thanks/')
          }}
          style={{
            layout: 'horizontal',
            color: 'gold',
            shape: 'rect',
            label: 'paypal',
            tagline: false
          }}
        />
        {/* <Button className={className}>
          {children ? children : <PayPalIcon/>}
        </Button> */}
      </div>
    )
  } else {
    return <></>
  }
}

export default PayPalButton
