import React, { useState, useRef } from 'react'
import style from 'styled-components'

import StripeButton from './stripebutton'
import PayPalButton from './paypalbutton'

const Container = style.div`
  color: cornflowerblue;
  font-weight: 600;
  background: whitesmoke;
  border-radius: .5em;
  padding: 1em;
  display: grid;
  grid-gap: .5em;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: [title] 2em [tiers] 3em [custom] 3em [payments] 5em;
  grid-template-areas: 
    "title title title"
    "d10 d20 d50"
    "info info amount"
    "stripe stripe paypal";
  place-items: stretch stretch;
  place-content: center center;
  padding-top: 1em;
  padding-bottom: 1em;

  @media (max-width: 480px) {
    padding-left: 0.25em;
    padding-right: 0.25em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: [title] 2em [tiers] 3em [custom] 3em [payments1] 5em [payments2] 2.5em;
    grid-template-areas: 
      "title title title"
      "d10 d20 d50"
      "info info amount"
      "stripe stripe stripe"
      "paypal paypal paypal"
  }
`

const Title = style.p`
  text-align: center;
  grid-area: title;
`

const Button = style.button`
  --selected-bg-color: cornflowerblue;
  --selected-text-color: white;
  --hover-bg-color: rgba(var(--selected-bg-color) 0.3);
  --hover-text-color: rgba(var(--selected-text-color) 0.3);
  --unselected-bg-color: white;
  --unselected-text-color: cornflowerblue;
  font-size: 120%;
  font-weight: 800;
  border: 5px transparent solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 4px 8px lightgray;
  ${props =>
    props.currentAmount === props.myAmount
      ? 'color:var(--selected-text-color);background: var(--selected-bg-color)'
      : 'color:var(--unselected-text-color);background: var(--unselected-bg-color);'};
  grid-area: d${props => props.myAmount};

  &:hover {
    ${props =>
      props.currentAmount === props.myAmount
        ? ''
        : `color: var(--hover-text-color);
      background: var(--hover-bg-color);`}
  };
`

const Info = style.div`
  grid-area: info;
  margin-right: 5%;
  place-self: center end;
`

const Input = style.input`
  font-size: 120%;
  text-align: right;
  border-radius: .3em;
  grid-area: amount;
  place-self: center start;
  height: 100%;
  width: 100%;
  border: none;
  box-shadow: 0 4px 8px lightgray;
  color: cornflowerblue;
`
const InputPrefix = style.div`
  grid-area: amount;
  place-self: center start;
  font-weight: 800;
  font-size: 130%;
  padding: .25em;
  color: cornflowerblue;
  margin-top: .2em;
`

const CreditCardButton = style(StripeButton)`
  grid-area: stripe;
  background: cornflowerblue;
  color: white;
  border: none;
  border-radius: .3em;
  box-shadow: 0 4px 8px lightgray;
  :hover {
    background: dimgray;
    color: white;
  }
`
const CreditCardText = style.div`
  font-size: 150%;
`

const PPButton = style(PayPalButton)`
  grid-area: paypal;
  place-self: center center;
  /*
  background: cornflowerblue;
  color: white;
  border: none;
  border-radius: .3em;
  box-shadow: 0 4px 8px lightgray;
  :hover {
    background: dimgray;
    color: white;
  }
  @media (min-width: 480px) {
    padding: 1em;
  }
  */
`

const DonateSection = () => {
  const [amount, setAmount] = useState(10)
  const inputEl = useRef(null)

  const updateDonation = amount => {
    if (amount !== 0) {
      inputEl.current.value = amount
    }
    setAmount(inputEl.current.value)
  }

  const tiers = ['10', '20', '50']

  return (
    <Container>
      <Title>Seleziona un importo:</Title>

      {tiers.map(value => (
        <Button
          currentAmount={amount}
          myAmount={value}
          onClick={() => updateDonation(value)}
        >
          {value}€
        </Button>
      ))}

      <Info>Scegli tu l'importo:</Info>
      <Input
        ref={inputEl}
        type="number"
        min="10"
        value={amount}
        onChange={() => updateDonation(0)}
      />
      <InputPrefix>€</InputPrefix>

      <CreditCardButton amount={amount}>
        <CreditCardText>Carta di Credito</CreditCardText>
      </CreditCardButton>
      <PPButton amount={amount} />
    </Container>
  )
}

export default DonateSection
