import React from 'react'
import style from 'styled-components'

import SEO from '../components/seo'
import Layout from '../components/layout'
import DonateSection from '../components/donate'
import IBAN from '../components/iban'

const Section = style.section`
  height: 100%;
  max-width: 30em;
  align-self: flex-start;
  padding: 1em;
  margin: auto;
  margin-top: 2%;
  text-align: justify;
  font-size: 1.2em;
`

const DonationsPage = () => {
  return (
    <Layout colorful={true}>
      <SEO title="Donazioni" />
      <Section>
        <h1>Fai una donazione</h1>
        Sostieni la nostra causa, ogni singola donazione, dalla piú grande alla
        piú piccola per noi é importante. Le vostre donazioni ci permettono di
        organizzare eventi di raccolta fondi e di acquistare attrezzature
        mediche per il reparto di Ematologia Pediatrica del Policlinico Umberto
        I di Roma (Via Benevento, 6).
        {/* <h4>Ecco come abbiamo utilizzato le vostre donazioni:</h4>
          <ReportTable/> */}
        <h2>Come donare?</h2>
        <h3>Dona il tuo 5x1000</h3>
        <p>Firma nella casella <strong><em>"Sostegno al volontariato"</em></strong> e inserisci il nostro codice fiscale nella dichiarazione dei redditi.<br/>Non ti costa nulla!</p>
        <IBAN>C.F.: 90080690572</IBAN>
        <h3>Donazione singola</h3>
        <h4>Carta di credito o PayPal</h4>
        <DonateSection />
        <h4>Bonifico bancario</h4>
        <p>Puoi effettuare un bonifico bancario su questo IBAN:</p>
        <IBAN>IT76 Y031 0403 2210 0000 0822 347</IBAN>
      </Section>
    </Layout>
  )
}

export default DonationsPage
